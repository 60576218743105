import React, { Component } from 'react';
import Harmonic from './Harmonic';
import * as Strings from 'harmonic-strings';

export default class HarmonicsList extends Component {
  render() {
    const list = this.props.harmonics.map(harmonic =>
      <Harmonic
        key={harmonic.baseStop.toFixed(2) + harmonic.halfStop.toFixed(2)}
        harmonic={harmonic}
        soundingNote={this.props.soundingNote}
        clef={this.props.instrument.clef}
        string={this.props.string} />
    );

    const stringName = Strings.Note.fromFrequency(this.props.string.frequency).toString();

    return list.length ? (
      <div className="harmonics-list">
        <h2>String {stringName} ({this.props.string.number}):</h2>
        <div className="harmonics-wrapper">{list}</div>
      </div>
    ) : null;
  }
}

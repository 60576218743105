import React, { Component } from 'react';
import * as Strings from 'harmonic-strings';
import './styles/Keyboard.css';

export default class Keyboard extends Component {
  static getKeyCents(startCents, startOctave = 0, endOctave = 10) {
    const keyCents = [];

    for (let octave = startOctave; octave <= endOctave; octave ++) {
      for (let index = 0, cents = (octave - 4) * 1200; index <= 11; index++, cents += 100) {
        if (cents < startCents) {
          continue;
        }
        keyCents.push(cents);
      }
    }

    return keyCents;
  }

  getKeys() {
    let startOctave = 4, startCents = 0, openStringCents = [];
    for (const string of this.props.instrument.strings) {
      const stringNote = Strings.Note.fromFrequency(string.frequency);
      stringNote.octave < startOctave && (startOctave = stringNote.octave);
      stringNote.cents < startCents && (startCents = stringNote.cents);
      openStringCents.push(stringNote.cents);
    }

    return this.constructor.getKeyCents(startCents, startOctave, startOctave + 6).map(cents => {
      const note = Strings.Note.fromCents(cents);
      let className = "keyboard-key";
      if (note.accidental !== Strings.Note.ACCIDENTALS.none) {
        className += " black";
      }
      if (openStringCents.includes(cents)) {
        className += " open-string";
      }
      if (cents === this.props.activeCents) {
        className += " active";
      }

      return (
        <div
          key={cents}
          className={className}
          onClick={() => {
            this.props.app.setCents(cents);
          }}
          title={note.toString() + (cents === 0 ? " (middle C)" : "")}>
        </div>
      );
    });
  }

  shouldComponentUpdate(nextProps) {
    return this.props.activeCents !== nextProps.activeCents
      || this.props.instrument !== nextProps.instrument;
  }

  render() {
    return (
      <div className="keyboard" aria-hidden="true">
        {this.getKeys()}
      </div>
    );
  }
}

import React, { Component } from 'react';
import * as Strings from 'harmonic-strings'
import HarmonicsList from './HarmonicsList';
import Keyboard from './Keyboard';
import './styles/App.css';
import ListenButton from "./ListenButton";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.handleNewInstrument = this.handleNewInstrument.bind(this);
    this.handleNewNote = this.handleNewNote.bind(this);
    this.calculateHarmonics = this.calculateHarmonics.bind(this);
    this.errorTimer = null;
    this.previousError = null;
    this.state = {
      instrument: Strings.Instrument.fromPreset('violin'),
      soundingNote: null,
      error: null,
      harmonics: new Map(),
      noteNameDefault: ''
    };
  }

  handleNewNote(event) {
    this.errorTimer && clearTimeout(this.errorTimer);
    this.setState({error: null, soundingNote: null, harmonics: new Map(), noteNameDefault: event.target.value});

    if (!event.target.value.length) {
      return;
    }

    try {
      const soundingNote = Strings.Note.fromName(event.target.value);
      this.setState({soundingNote});
      this.calculateHarmonics(soundingNote, this.state.instrument);
      this.previousError = null;
    } catch (e) {
      if (this.previousError) {
        this.setState({error: e});
      } else {
        this.errorTimer = setTimeout(() => {
          this.setState({error: e});
        }, 500);
      }

      this.previousError = e;
    }
  }

  handleNewInstrument(event) {
    if (event.target.value.length > 0) {
      const instrument = Strings.Instrument.fromPreset(event.target.value);
      this.setState({instrument});
      if (this.state.soundingNote) {
        this.calculateHarmonics(this.state.soundingNote, instrument);
      }
    }
  }

  calculateHarmonics(soundingNote, instrument) {
    let harmonics = new Map();
    const calc = new Strings.HarmonicCalculator();

    for (let instrumentString of instrument.strings) {
      harmonics.set(instrumentString.number, calc.findHarmonics(soundingNote, instrumentString));
    }

    this.setState({harmonics, instrument});
  }

  setCents(cents) {
    this.errorTimer && clearTimeout(this.errorTimer);
    this.setState({error: null, soundingNote: null, harmonics: new Map()});

    const soundingNote = Strings.Note.fromCents(cents);
    this.setState({soundingNote, noteNameDefault: soundingNote.toString()});
    this.calculateHarmonics(soundingNote, this.state.instrument);
  }

  render() {
    let harmonicsLists = [];
    for (let [stringNumber, harmonics] of this.state.harmonics) {
      if (harmonics.length) {
        harmonicsLists.push(
          <HarmonicsList
            key={stringNumber}
            harmonics={harmonics}
            soundingNote={this.state.soundingNote}
            instrument={this.state.instrument}
            string={this.state.instrument.getString(stringNumber)} />
        );
      }
    }

    if (!harmonicsLists.length && this.state.soundingNote) {
      harmonicsLists = <p>No harmonics found.</p>;
    }

    const soundingFrequency = this.state.soundingNote ? this.state.soundingNote.getFrequency() : 0;

    return (
      <div className="app">
        <form onSubmit={(event) => {event.preventDefault();}}>
          <label htmlFor="instrument">Instrument:</label>
          <select name="instrument" onChange={this.handleNewInstrument}>
            <option value="violin">Violin</option>
            <option value="viola">Viola</option>
            <option value="cello">Cello</option>
            <option value="double bass">Double bass</option>
          </select><br />
          <label htmlFor="note">Sounding note:</label>
          <input
            autoComplete="off"
            autoFocus
            type="text"
            name="note"
            placeholder="Note name (e.g. a5)"
            value={this.state.noteNameDefault}
            onChange={this.handleNewNote} />
          {this.state.error &&
            <p className="error-message">{this.state.error.message}</p>}
        </form>
        <Keyboard
          app={this}
          activeCents={this.state.soundingNote ? Math.round(this.state.soundingNote.cents * 100) / 100 : null}
          instrument={this.state.instrument} />
        {this.state.soundingNote &&
          <p className="sounding-note">Sounding note: {this.state.soundingNote.toString()} ({soundingFrequency.toFixed(1)}Hz) <ListenButton frequency={soundingFrequency} /></p>}
        {harmonicsLists}
      </div>
    );
  }
}

let audioCtx;
function getContext() {
  if (audioCtx === undefined) {
    const audioFunction = window.AudioContext || window.webkitAudioContext;

    audioCtx = (new audioFunction());
  }

  return audioCtx;
}

function freqToGain(freq, max = 0.1, min = 0.01) {
  // Scale the gain value for the frequency.
  let gain = Math.round((1 / freq * max * 100) * 100) / 100;
  gain = Math.min(Math.max(gain, min), max);
  console.log(gain);

  return gain;
}

export default class Audio {
  constructor() {
    this.initialized = false;
  }

  static get isAvailable() {
    return window.AudioContext !== undefined || window.webkitAudioContext !== undefined;
  }

  initialize() {
    if (this.initialized) {
      return;
    }
    this.audioCtx = getContext();
    this.osc = this.audioCtx.createOscillator();
    this.gainNode = this.audioCtx.createGain();
    this.gainNode.gain.value = 0;
    this.osc.connect(this.gainNode);
    this.gainNode.connect(this.audioCtx.destination);
    this.osc.start();
  }

  play(frequency) {
    this.initialize();
    this.osc.frequency.setValueAtTime(frequency, this.audioCtx.currentTime, 0.1);
    this.gainNode.gain.setTargetAtTime(freqToGain(frequency), this.audioCtx.currentTime, 0.2);
  }

  stop() {
    this.gainNode &&
      this.gainNode.gain.setTargetAtTime(0, this.audioCtx.currentTime, 0.2);
  }
}

import React, { Component } from 'react'
import * as Strings from 'harmonic-strings';
import * as Notation from './Notation';
import './styles/HarmonicInfo.css';
import ListenButton from './ListenButton';

function getNoteNameFromStringLength (stringLength, stringFrequency, relatedNote) {
  return Notation.freqToNote(
    Strings.Harmonic.getSoundingFrequency(1, stringLength, stringFrequency),
    relatedNote
  ).toString();
}

const intervalNames = new Map([
  [7/8, 'Maj. 2nd'],
  [6/7, 'Maj. 2nd'],
  [5/6, 'Min. 3rd'],
  [4/5, 'Maj. 3rd'],
  [3/4, '4th'],
  [2/3, '5th'],
  [1/2, '8ve']
]);

function getIntervalName(fraction) {
  for (let [interval, name] of intervalNames) {
    if (Math.abs(fraction - interval) < 0.00001) {
      return name;
    }
  }

  return fraction.toPrecision(2);
}

export default class HarmonicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {detailsOpen: false};
  }

  render() {
    const harmonic = this.props.harmonic;

    const actualSoundingNote = Notation.freqToNote(harmonic.frequency, this.props.soundingNote);

    let type, typeClass, ratio, difference, interval;
    if (harmonic.isNatural) {
      if (harmonic.isOpenString) {
        type = 'Open string';
        typeClass = 'natural open';
        ratio = '1/1';
      } else {
        type = 'Natural';
        typeClass = 'natural';

        const numerator = Math.round(harmonic.number - harmonic.halfStop * harmonic.number);
        ratio = numerator + '/' + harmonic.number;
      }
      difference = actualSoundingNote.cents - this.props.soundingNote.cents;
      difference = (difference > 0 ? '+' : '') + Math.round(difference) + '¢';
    } else {
      type = 'Artificial';
      typeClass = 'artificial';
      interval = getIntervalName(harmonic.halfStop / harmonic.baseStop);
    }

    const distanceBelow = ((1 - harmonic.baseStop) || (1 - harmonic.halfStop)) * this.props.string.physicalLength,
      distanceAbove = harmonic.halfStop * this.props.string.physicalLength,
      distanceBetween = harmonic.isNatural ? null : Math.abs(harmonic.baseStop - harmonic.halfStop) * this.props.string.physicalLength;

    return (
      <div className="harmonic-info">
        <div className="row">
          <div className={`harmonic-type ${typeClass}`}>{type}</div>
          {ratio && <div className="harmonic-ratio">{ratio}</div>}
          {interval && <div className="harmonic-interval">{interval}</div>}
          {difference && <div className="harmonic-difference" title={`Difference to requested sounding pitch: ${difference}`}>{difference}</div>}
        </div>
        <div className="row">
          <div className="harmonic-listen">
            <ListenButton frequency={harmonic.frequency} />
          </div>
          <div className="harmonic-details-button">
            <button
              onClick={() => {this.setState(prevState => ({detailsOpen: !prevState.detailsOpen}));}}
              className={this.state.detailsOpen && 'active'}>
              Details
            </button>
          </div>
        </div>
        {this.state.detailsOpen &&
        <div className="harmonic-details">
          <div className="row">
            {!harmonic.isNatural &&
            <div className="harmonic-base-stop">
              Base stop: {getNoteNameFromStringLength(harmonic.baseStop, harmonic.stringFrequency, this.props.soundingNote)}
            </div>
            }
            {!harmonic.isOpenString &&
            <div className="harmonic-half-stop">
              Half stop: {getNoteNameFromStringLength(harmonic.halfStop, harmonic.stringFrequency, this.props.soundingNote)}
            </div>
            }
          </div>
          <div className="row">
            <div className="harmonic-sounding">
              Sounding: {actualSoundingNote.toString()} ({harmonic.frequency.toFixed(1)}Hz)
            </div>
          </div>
          <div className="row">
            <div className="harmonic-string-distance">
              Distance below: {distanceBelow.toFixed(1)}mm<br />
              Distance above: {distanceAbove.toFixed(1)}mm
            </div>
          </div>
          {distanceBetween &&
          <div className="row">
            <div className="harmonic-string-distance-between">
              Distance between: {distanceBetween.toFixed(1)}mm
            </div>
          </div>
          }
        </div>
        }
      </div>
    );
  }
}

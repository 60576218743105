import React, { Component } from 'react';
import HarmonicInfo from './HarmonicInfo';
import HarmonicNotation from './HarmonicNotation';
import './styles/Harmonic.css';

export default class Harmonic extends Component {
  render() {
    return (
      <div className="harmonic">
        <HarmonicInfo
          harmonic={this.props.harmonic}
          soundingNote={this.props.soundingNote}
          string={this.props.string}
        />
        <HarmonicNotation
          harmonic={this.props.harmonic}
          soundingNote={this.props.soundingNote}
          string={this.props.string}
          clef={this.props.clef}
        />
      </div>
    );
  }
}

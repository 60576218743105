import React, { Component } from 'react';
import Audio from './Audio.js';

export default class ListenButton extends Component {
  constructor(props) {
    super(props);
    this.toggleSound = this.toggleSound.bind(this);
    this.state = {playing: false};
    this.stopTimeout = false;
    this.audio = new Audio();
    this._mounted = true;
  }

  toggleSound(event) {
    event.preventDefault();
    this.stopTimeout && clearTimeout(this.stopTimeout);
    if (this.state.playing) {
      this.audio.stop();
      this.setState({playing: false});
      return;
    }
    this.audio.play(this.props.frequency);
    this.setState({playing: true});
    this.stopTimeout = setTimeout(() => {
      this.audio.stop();
      this._mounted && this.setState({playing: false});
    }, 2000);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return (
      <button
        onClick={this.toggleSound}
        disabled={!Audio.isAvailable || this.props.frequency >= 22050}
        className={this.state.playing && 'active'}>
        Listen
      </button>
    );
  }
}

import * as Strings from 'harmonic-strings';
import Vex from 'vexflow';
const VF = Vex.Flow;

export function freqToNote(freq, relatedNote) {
  return Strings.Note.fromFrequency(freq, 440, relatedNote === undefined ? [] : [relatedNote.accidental]);
}

function isAlmostEqual(a, b) {
  return Math.abs(a - b) < 0.0000000001;
}

function stopToNote(stop, stringFrequency, relatedNote) {
  return freqToNote(Strings.Harmonic.getSoundingFrequency(1, stop, stringFrequency), relatedNote);
}

// A duration of 2 or greater means the note has a stem, which seems to cause
// fewer layout conflicts. See https://github.com/0xfe/vexflow/issues/321
const notesToVfStaveNote = function (notes, {glyphs = {}, duration = '2', clef} = {}) {
  const accidentalMap = {
    [Strings.Note.ACCIDENTALS.none]: '',
    [Strings.Note.ACCIDENTALS.natural]: 'n',
    [Strings.Note.ACCIDENTALS.flat]: 'b',
    [Strings.Note.ACCIDENTALS.sharp]: '#',
    [Strings.Note.ACCIDENTALS.doubleFlat]: 'bb',
    [Strings.Note.ACCIDENTALS.doubleSharp]: '##',
    [Strings.Note.ACCIDENTALS.quarterSharp]: '+',
    [Strings.Note.ACCIDENTALS.quarterFlat]: 'd',
    [Strings.Note.ACCIDENTALS.threeQuarterSharp]: '++',
    [Strings.Note.ACCIDENTALS.threeQuarterFlat]: 'db'
  };

  let keys = [];

  for (let index in notes) {
    if (notes.hasOwnProperty(index)) {
      const note = notes[index];
      let key = note.name + '/' + note.octave;
      if (glyphs[index] !== undefined) {
        key += '/' + glyphs[index];
      }
      keys.push(key);
    }
  }

  let staveNote = new VF.StaveNote({keys, duration, clef, auto_stem: true});

  for (let index in notes) {
    if (notes.hasOwnProperty(index)) {
      const note = notes[index];
      if (Math.abs(note.difference) >= 5) {
        let annotation = new VF.Annotation(
          (note.difference > 0 ? '+' : '') + Math.round(note.difference) + '¢'
        );
        annotation.setVerticalJustification(VF.Annotation.VerticalJustify.CENTER_STEM);
        annotation.setJustification(VF.Annotation.Justify.RIGHT);
        staveNote.addAnnotation(index, annotation);
      }

      // This uses addAccidental() instead of the key string for the accidental, to
      // enable quarter-tone accidental support.
      // See https://github.com/0xfe/vexflow/issues/578
      let vfAccidentalType = accidentalMap[note.accidental];
      if (vfAccidentalType) {
        staveNote.addAccidental(index, new VF.Accidental(vfAccidentalType));
      }
    }
  }

  return staveNote;
};

export function renderHarmonic({harmonic, element, width = 200, height = 180, soundingNote, clef, stringNumber = 'I'} = {}) {
  element.innerHTML = '';

  const vf = new VF.Factory({renderer: {elementId: element, width, height}});
  const score = vf.EasyScore();
  const system = vf.System({width, height, x: 0});
  const voices = [];

  score.set({clef});

  const stringNote = freqToNote(harmonic.stringFrequency);
  const halfStopNote = stopToNote(harmonic.halfStop, harmonic.stringFrequency, soundingNote);
  const openArticulation = new VF.Articulation('ah');
  openArticulation.setPosition(VF.Modifier.Position.ABOVE);

  let vfNotes = [], time = '2/4';

  if (harmonic.isOpenString) {
    vfNotes.push(notesToVfStaveNote([stringNote], {clef}).addArticulation(0, openArticulation));
  }
  else {
    const vfStringNumber = new VF.Annotation(stringNumber);
    vfStringNumber.setVerticalJustification(VF.Annotation.VerticalJustify.TOP);
    vfStringNumber.setFont('serif', '8pt', 'normal');

    if (harmonic.isNatural) {
      if (isAlmostEqual(0.5, harmonic.halfStop)) {
        vfNotes.push(
          notesToVfStaveNote([halfStopNote], {clef})
            .addArticulation(0, openArticulation)
            .addModifier(0, vfStringNumber)
        );
      } else {
        vfNotes.push(
          notesToVfStaveNote([halfStopNote], {clef, glyphs: {0: 'D0'}})
            .addModifier(0, vfStringNumber)
        );
      }
    } else {
      const baseStopNote = stopToNote(harmonic.baseStop, harmonic.stringFrequency, soundingNote);
      vfNotes.push(notesToVfStaveNote(
        [baseStopNote, halfStopNote],
        {clef, glyphs: {1: 'D0'}}
      ).addModifier(1, vfStringNumber));
    }
  }

  voices.push(score.voice(vfNotes, {time, strict: false}));

  system.addStave({
    voices,
    spaceAbove: 5,
    spaceBelow: 5,
    options: {
      left_bar: false,
      right_bar: false
    }
  }).addClef(clef);

  vf.draw();
}

import React, { Component } from 'react'
import * as Notation from './Notation';
import './styles/HarmonicNotation.css'

export default class HarmonicNotation extends Component {
  constructor(props) {
    super(props);
    this.updateNotation = this.updateNotation.bind(this);
  }

  updateNotation(element) {
    Notation.renderHarmonic({
      harmonic: this.props.harmonic,
      soundingNote: this.props.soundingNote,
      element: element,
      width: 360,
      height: element.offsetHeight || 240,
      clef: this.props.clef,
      stringNumber: this.props.string.number
    });
  }

  componentDidMount() {
    this.notationElement && this.updateNotation(this.notationElement);
  }

  componentDidUpdate() {
    this.notationElement && this.updateNotation(this.notationElement);
  }

  render() {
    return (
      <div className="notation" ref={(element) => { this.notationElement = element; }}/>
    );
  }
}
